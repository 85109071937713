<template>
    <header>
        <header-menu/>
        <section class="bg-white">
            <header-banner/>
        </section>
        <section class="bg-site-light">
            <b-container id="scroll-from-header-banner">
                <b-row>
                    <b-col cols="12" md="12">
                        <h2 class="font-weight-bold text-center text-lg-left mb-1 mb-md-3">
                            Откройте свое дело с ВТБ
                        </h2>
                        <div class="text text-center text-lg-left mb-4 pb-md-3">
                            Сэкономьте ресурсы, используя все возможности сервиса
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <u-img-text :rows="rows" class="images" class-box="col-md-4"/>
        </section>
        <section class="bg-white">
            <header-form/>
        </section>
    </header>
</template>

<script>
    import {ImgTextWithTitle} from "../../classes/imgText";

    export default {
        name: "index",
        computed: {
            rows() {
                const rows = [];

                rows.push(new ImgTextWithTitle('Получите заполненные документы совершенно бесплатно', '/img/icon/ruble.svg', 'Бесплатно'));
                rows.push(new ImgTextWithTitle('Не более 10 минут вам потребуется на заполнение форм', '/img/icon/timer.svg', 'Быстро'));
                rows.push(new ImgTextWithTitle('Вы можете получить консультацию юриста и проверить документы дистанционно', '/img/icon/check-circle.svg', 'Онлайн'));

                return rows;
            }
        },
        components: {
            HeaderMenu: () => import(/* webpackChunkName:"header-menu"*/'./Menu'),
            HeaderBanner: () => import(/* webpackChunkName:"header-banner"*/'./Banner'),
            UImgText: () => import(/* webpackChunkName:"u-img-text"*/'../UImgText'),
            HeaderForm: () => import(/* webpackChunkName:"header-form"*/'./Form'),
        }
    }
</script>

<style lang="scss">
    #app {
        header {
            section {
                &:nth-child(2) {
                    padding: 100px 0 90px 0;
                    @media screen and (max-width: 1440px) {
                        padding: 150px 0 135px 0;
                    }
                    @media screen and (max-width: 1280px) {
                        padding: 70px 0;
                    }
                    @include _lg {
                        padding: 30px 0;
                    }
                }

                &:nth-child(3) {
                    padding: 70px 0;
                    @media screen and (max-width: 1280px) {
                        padding: 60px 0;
                    }
                    @include _lg {
                        padding: 30px 0;
                    }
                }
            }
        }
    }

    .images {
        img {
            height: 63px;
        }

        @include _lg {
            img {
                height: 42px;
            }
        }
    }
</style>