class ImgText {
    subtitle = 'default';
    img = 'default';

    constructor(subtitle = '', img = '') {
        this.subtitle = subtitle;
        this.img = img;
    }

    getHtml() {
        return '' +
            '<div class="d-flex mb-3 mb-md-4 pb-md-2">' +
            '<img class="img-fluid mb-auto mr-3" src="' + this.img + '"/>' +
            '<div class="text my-auto">' + this.subtitle + '</div>' +
            '</div>' +
            '';
    }
}

class ImgTextWithTitle extends ImgText {
    title = 'default';

    constructor(subtitle = '', img = '', title = '') {
        super(subtitle, img);
        this.title = title;
    }

    getHtml() {
        return '' +
            '<div class="d-flex mb-3 mb-lg-0">' +
            '<img class="img-fluid mb-auto mr-3" src="' + this.img + '" :alt="' + this.subtitle + '" />' +
            '<div>' +
            '<p class="text-size-md font-weight-bold mb-0 mb-lg-1">' + this.title + '</p>' +
            '<p class="text mb-0">' + this.subtitle + '</p>' +
            '</div>' +
            '</div>' +
            '';
    }
}

export {ImgText, ImgTextWithTitle};